<script setup>
import {useLocalStorage} from "@vueuse/core";
import {useDigitalOrder} from "~/composables/DigitalOrder";

const props = defineProps({
  isFormEnabled: {
    type: Boolean,
    default: false
  },
  isUploadEnabled: {
    type: Boolean,
    default: false
  }, 
  files : {
    type: Array,
    default: []
  },
  operatorData: {
    type: Object,
    default: {}
  },
  clientType: {
    type: String,
    default: '"entrepreneur"'
  },
  isAgreementsChecked: {
    type: Boolean,
    default: () => false
  }
})


const backend = useBackend();
const route = useRoute();
const digitalOrder = useDigitalOrder();

const anotherAddress = ref(false);
const yesno = new Map([[0, 'NIE'], [1, 'TAK']]);
const isLoggedAsOperator = ref(false);
const errors = ref({
  "pin": "wpisano nieprawidłowy pin",
  "formCompany.nip": "",
  "formCompany.companyName": "",
  "formCompany.companyForm": "",
  "formCompany.companyRole": "",
  "formCompany.employeesAmount": "",
  "formCompany.bookkeepingType": "",
  "formCompany.lastYearIncome": "",
  "formCompany.numberOfDependents": "",
  "formPersonalCompany.name": "",
  "formPersonalCompany.surname": "",
  "formPersonalCompany.documentNumber": "",
  "formPersonalCompany.documentAuthority": "",
  "formPersonalCompany.documentExpiration": "",
  "formPersonalCompany.pesel": "",
  "formPersonalCompanySpouse.name": "",
  "formPersonalCompanySpouse.surname": "",
  "formPersonalCompanySpouse.documentNumber": "",
  "formPersonalCompanySpouse.documentAuthority": "",
  "formPersonalCompanySpouse.documentExpiration": "",
  "formPersonalCompanySpouse.pesel": "",
  "formPersonalCompany.maritalStatus": "",
  "formPersonalCompany.propertySeparation": "",
  "formCompanyAddress.postalCode": "",
  "formCompanyAddress.city": "",
  "formCompanyAddress.street": "",
  "formCompanyAddress.buildNumber": "",
  "formCompanyCorrespondAddress": "",
  "formCompanyCorrespondAddress.postalCode": "",
  "formCompanyCorrespondAddress.city": "",
  "formCompanyCorrespondAddress.street": "",
  "formCompanyCorrespondAddress.buildNumber": "",

  "formConsumer.name": "",
  "formConsumer.surname": "",
  "formConsumer.documentNumber": "",
  "formConsumer.documentAuthority": "",
  "formConsumer.documentExpiration": "",
  "formConsumer.pesel": "",
  "formConsumer.maritalStatus": "",
  "formConsumer.propertySeparation": "",
  "formConsumerSpouse.name": "",
  "formConsumerSpouse.surname": "",
  "formConsumerSpouse.documentNumber": "",
  "formConsumerSpouse.documentAuthority": "",
  "formConsumerSpouse.documentExpiration": "",
  "formConsumerSpouse.pesel": "",
  "formConsumerAddress.postalCode": "",
  "formConsumerAddress.city": "",
  "formConsumerAddress.street": "",
  "formConsumerAddress.buildNumber": "",
  "formConsumerCorrespondAddress.postalCode": "",
  "formConsumerCorrespondAddress.city": "",
  "formConsumerCorrespondAddress.street": "",
  "formConsumerCorrespondAddress.buildNumber": "",
  "formConsumerEmployment.employerName": "",
  "formConsumerEmployment.position": "",
  "formConsumerEmployment.employmentFrom": "",
  "formConsumerEmployment.salaryNetto": "",
  "formConsumerEmployment.houseHold": "",
  "formConsumerEmployment.formEmployerAddress": "",
  "formConsumerEmployment.formEmployerAddress.postalCode": "",
  "formConsumerEmployment.formEmployerAddress.city": "",
  "formConsumerEmployment.formEmployerAddress.street": "",
  "formConsumerEmployment.formEmployerAddress.buildNumber": "",
  "agreements.agreement1": "",
  "agreements.agreement2": "",
  "agreements.agreement3": "",
  "agreements.agreement4": "",
  "agreements.agreement5": "",
  "agreements.agreement6": "",
});

const resetErrors = () => {
  Object.keys(errors.value).forEach(key => errors.value[key] = "");
}

const formHasErrors = ref(false);
const formSaved = ref(false);
const showMessage = ref(false);

const submit = async() => {
  if(allAgreementsChecked) {
    formLoading.value = true;
    if(lastVersionFormData.value.isFormEnabled) {
      await submitForm();
    }
    if(lastVersionFormData.value.isUploadEnabled || props.isUploadEnabled) {
      if(formHasErrors.value) {
        formLoading.value = false;
        return
      } else {
        await sendFiles();
      }
    }
    formLoading.value = false;
  } else {
    formSaved.value = false;
    formHasErrors.value = true;
  }
}

const submitForm = async () => {
  try {
    formSaved.value = false;
    formHasErrors.value = false;
    resetErrors();
    const payload = JSON.stringify(applicationData.formData)
    const response = await backend.postFormLiczakOffer(route.params.uuid, payload);
    if(response.status === 422) {
      const serverErrors = response.data.data.errors;
      for (const [key, value] of Object.entries(serverErrors)) {
        errors.value[key] = value[0];
      }
      formSaved.value = false;
      formHasErrors.value = true;
    } else {
      formHasErrors.value = false;
      formSaved.value = true;
    }
  } catch (error) {
    console.error('Wystąpił błąd:', error)
  }

}

const sendFiles = async () => {
  formSaved.value = false;
  formHasErrors.value = false;
  try {
    const formData = new FormData();

    const files = applicationData.formData?.files;
    for (const [key, value] of Object.entries(applicationData.formData.agreements)) {
      formData.append(`agreements[${key}]`, value ? '1' : '0');
    }
    if (Array.isArray(files)) {
      files.forEach((file) => {
        formData.append('files[]', file);
      });
    }

    if(!files) {
      formSaved.value = true;
      return;
    }

    const response = await backend.uploadFilesLiczakOffer(route.params.uuid, formData);

    if(response?.data?.error) {
      formSaved.value = false;
      formHasErrors.value = true;
    } else {
      formSaved.value = true;
    }

  } catch (error) {
    throw error;
  }
};

const setErrors = (err) => {
  resetErrors();
  Object.keys(err).forEach(key => errors.value[key] = err[key]);
}

defineExpose({
  submit,
  setErrors
})

watch([formSaved, formHasErrors], () => {
  if (formSaved.value || formHasErrors.value) {
    showMessage.value = true;
    setTimeout(() => {
      showMessage.value = false;
    }, 2000);
  }
});


function handleFilesUpdate(newFiles) {
  applicationData.formData.files = newFiles;
}
const lastVersionFormData = ref({});
const applicationData = reactive({
  files: [],
  formData: {
    clientDTO: {
      nip: '',
      name: '',
      number_of_employees: '',
      company_form: '',
      book_keeping_type: '',
      last_year_income: '',
      number_of_dependents: '',
    },
    mainAddress: {
      postal_code: '',
      city: '',
      street: '',
      house_no: '',
      flat_no: '',
    },
    correspondAddress: {
      postal_code: '',
      city: '',
      street: '',
      house_no: '',
      flat_no: '',
    },
    connectedPerson: {
      roles: '',
      first_name: '',
      last_name: '',
      document_number: '',
      document_authority: '',
      document_expiration_date: '',
      pesel: '',
      marital_status: '',
      indefinitely: 0,
      separate_property: 0,
      politically_exposed: 0,
      martial_status: '',
      document_indefinitely: 0,
    },
    spousePerson: {
      first_name: '',
      last_name: '',
      document_number: '',
      document_authority: '',
      document_expiration_date: '',
      document_indefinitely: 0,
      pesel: '',
      politically_exposed: 0,
    },
    agreements: {
    agreement1: false,
    agreement2: false,
    agreement3: false,
    agreement4: false,
    agreement5: false,
    agreement6: false,
  }
  },
});
const allAgreementsChecked = computed(() => {
  const agreements = applicationData.formData.agreements;
  return Object.values(agreements).every(value => value === true);
});

const isAnotherAddress = computed(() => {
  const address = applicationData.formData.correspondAddress;
  return (
    anotherAddress.value ||
    !!address.city || 
    !!address.street || 
    !!address.house_no || 
    !!address.flat_no
  );
});
const setup = await digitalOrder.setup();
const setupOptions = reactive(setup)
const formLoading = ref(false);
const showForm = ref(false);
const agreementAdded = ref(false);
watch(agreementAdded, (newValue) => {
  if (newValue) {
  for (const key in applicationData.formData.agreements) {
    applicationData.formData.agreements[key] = true;
  }
  applicationData.formData.agreements = { ...applicationData.formData.agreements };
}
});

function shallowUpdate(target, source) {
  // Lista pól, które mają być konwertowane na liczby
  const numericFields = [
    'document_indefinitely',
    'politically_exposed',
    'separate_property'
  ];

  for (const key in source) {
    if (key in target) {
      if (typeof target[key] === 'object' && target[key] !== null && typeof source[key] === 'object') {
        shallowUpdate(target[key], source[key]);
      } else {
        const isNumericField = numericFields.includes(key);
        const isInScope =
          target === target.connectedPerson || target === target.spousePerson || target.hasOwnProperty(key);

        const newValue = isNumericField && isInScope ? Number(source[key]) : source[key];

        if (newValue !== null && newValue !== '' && newValue !== undefined) {
          agreementAdded.value = true;
        }

        target[key] = newValue;
      }
    }
  }
}

const handlePinValid = (formData) => {
  lastVersionFormData.value = formData?.data;
  shallowUpdate(applicationData.formData, lastVersionFormData.value.formData || {});
  if (lastVersionFormData.value.files && Array.isArray(lastVersionFormData.value.files)) {
    applicationData.files = [...lastVersionFormData.value.files];
  } else {
    applicationData.files = [];
  }
  showForm.value = true;
};
const handleBlur = async (nipValue) => {
  try {
    const result = await backend.getCompanyData(nipValue);
      if(result?.data?.name) {
        errors.value['formCompany.nip'] = "";
        applicationData.formData.clientDTO.name = result.data['name'];
      } else {
        errors.value['formCompany.nip'] = 'Nie znaleziono firmy o podanym NIP';
      }
      if(result?.data?.city) {
        applicationData.formData.mainAddress.city = result.data['city'];
      }
      if(result?.data?.street) {
        applicationData.formData.mainAddress.street = result.data['street'];
      }
      if(result?.data?.postCode) {
        applicationData.formData.mainAddress.postal_code = result.data['postCode'];
      }
      if(result?.data?.streetNumber) {
        applicationData.formData.mainAddress.house_no = result.data['streetNumber'];
      }
      if(result?.data?.houseNumber) {
        applicationData.formData.mainAddress.flat_no = result.data['houseNumber'];
      }
      if(result.data.isJdg) {
        applicationData.formData.clientDTO.company_form = 'JDG'
      }

  } catch (error) {
    console.error('Błąd podczas pobierania danych firmy:', error);
  }
};

async function checkIsLoggedOperator() {
  const userAuth = useLocalStorage('user_auth');
  if (userAuth.value) {
    try {
      const response = await backend.checkIsLoggedOperator(userAuth.value, route.params.uuid);
      if (response.status === 200) {
        isLoggedAsOperator.value = true;
        handlePinValid(response?.data)
      } else {
        isLoggedAsOperator.value = false;
      }
    } catch (error) {
      console.error('Error while checking operator login:', error);
    }
  }
}

onMounted(() => {
  checkIsLoggedOperator();
  if(props.isAgreementsChecked) {
    agreementAdded.value = true;
  }
});

</script>

<template>
  <div v-if="isUploadEnabled || isFormEnabled" class="print:hidden">
    <FormPinValidation
      v-if="!showForm && !(!isFormEnabled && isUploadEnabled)"
      @pin-valid="handlePinValid"
      :isLoggedAsOperator="isLoggedAsOperator"
    />
    <div
     class="rounded shadow-box p-6">
     <p class="text-lg font-extralight" v-if="clientType === 'consumer'">Dotyczy tylko JDG. W przypadku innej formy prowadzenia działalności gospodarczej prosimy o kontakt z:
      {{operatorData?.owner}} /  {{operatorData?.email}} /  {{operatorData?.phone}}</p>
      <div v-if="lastVersionFormData.isFormEnabled">
        <div class="text-xl font-extralight mb-4">Dane zamawiającego</div>
        <div class="flex">
          <div class="w-full">
          </div>
        </div>
        <div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText  @blur="handleBlur" label="NIP" v-model="applicationData.formData.clientDTO.nip" :error="errors['formCompany.nip']" />
            <InputText label="Nazwa firmy" v-model="applicationData.formData.clientDTO.name" :error="errors['formCompany.companyName']" />
            <InputDropDown
              returnSimpleValue
              label="Forma działalności gospodarczej"
              :options="setupOptions.companyForm"
              v-model="applicationData.formData.clientDTO.company_form"
              :preset="applicationData.formData.clientDTO.company_form"
              :error="errors['formCompany.companyForm']"
          />
          <InputDropDown
              returnSimpleValue
              label="Rola pełniona w firmie"
              :options="setupOptions.companyRoles"
              v-model="applicationData.formData.connectedPerson.roles"
              :preset="applicationData.formData.connectedPerson.roles"
              :error="errors['formCompany.companyRole']"
          />
            <InputText label="Liczba pracowników" v-model="applicationData.formData.clientDTO.number_of_employees" :error="errors['formCompany.employeesAmount']" />
            <InputDropDown
              returnSimpleValue
              label="Forma księgowości"
              :options="setupOptions.bookKeepingTypes"
              v-model="applicationData.formData.clientDTO.book_keeping_type"
              :preset="applicationData.formData.clientDTO.book_keeping_type"
              :error="errors['formCompany.bookkeepingType']"
          />
            <InputText label="Przychód za ostatni rok" v-model="applicationData.formData.clientDTO.last_year_income" :error="errors['formCompany.lastYearIncome']" />
            <InputText label="Liczba osób na utrzymaniu (bez właściciela)" v-model="applicationData.formData.clientDTO.number_of_dependents" :error="errors['formCompany.numberOfDependents']" />
          </div>
          <div class="pb-4 pt-8 font-extralight">Adres siedziby firmy</div>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-2" v-model="applicationData.formData.mainAddress.postal_code" :error="errors['formCompanyAddress.postalCode']" />
            <InputText label="Miejscowość" class="col-span-2" v-model="applicationData.formData.mainAddress.city" :error="errors['formCompanyAddress.city']" />
            <InputText label="Ulica" class="col-span-2" v-model="applicationData.formData.mainAddress.street" :error="errors['formCompanyAddress.street']" />
            <InputText label="Nr budynku" v-model="applicationData.formData.mainAddress.house_no" :error="errors['formCompanyAddress.buildNumber']" />
            <InputText label="Nr lokalu" v-model="applicationData.formData.mainAddress.flat_no" />
          </div>
          <div class="pt-4">
            <InputCheckBox :checked="isAnotherAddress" @change="(v) => anotherAddress = v">Mam inny adres korespondencyjny</InputCheckBox>
          </div>

          <Accordion :expanded="isAnotherAddress">
            <div class="pb-4 pt-8 font-extralight">Adres korespondencyjny</div>
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-2" v-model="applicationData.formData.correspondAddress.postal_code" :error="errors['formCompanyCorrespondAddress.postalCode']" />
              <InputText label="Miejscowość" class="col-span-2" v-model="applicationData.formData.correspondAddress.city" :error="errors['formCompanyCorrespondAddress.city']" />
              <InputText label="Ulica" class="col-span-2" v-model="applicationData.formData.correspondAddress.street" :error="errors['formCompanyCorrespondAddress.street']" />
              <InputText label="Nr budynku" v-model="applicationData.formData.correspondAddress.house_no" :error="errors['formCompanyCorrespondAddress.buildNumber']" />
              <InputText label="Nr lokalu" v-model="applicationData.formData.correspondAddress.flat_no" />
            </div>
          </Accordion>

          <div class="pb-4 pt-8 font-extralight">Dane osobowe</div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText label="Imię" v-model="applicationData.formData.connectedPerson.first_name" :error="errors['formPersonalCompany.name']" />
            <InputText label="Nazwisko" v-model="applicationData.formData.connectedPerson.last_name" :error="errors['formPersonalCompany.surname']" />
            <InputText label="Seria i numer dowodu osobistego" v-model="applicationData.formData.connectedPerson.document_number" :error="errors['connectedPerson.document_number']" />
            <InputText label="Wydany przez" v-model="applicationData.formData.connectedPerson.document_authority" :error="errors['formPersonalCompany.documentAuthority']" />
            <InputText :mask="['####-##-##', 'YYYY-MM-DD']" label="Data ważności dowodu osobistego" v-model="applicationData.formData.connectedPerson.document_expiration_date" :error="errors['formPersonalCompany.documentExpiration']" />
            <div>
              <InputCheckBox @change="(v) => applicationData.formData.connectedPerson.document_indefinitely = v"
                            :checked="applicationData.formData.connectedPerson.document_indefinitely">Posiadam dowód ważny bezterminowo
              </InputCheckBox>
            </div>
            <InputText label="PESEL" v-model="applicationData.formData.connectedPerson.pesel" :error="errors['connectedPerson.pesel']" />
            <div>
              <InputCheckBox @change="(v) => applicationData.formData.connectedPerson.politically_exposed = v"
                            :checked="applicationData.formData.connectedPerson.politically_exposed">Zajmuję eksponowane stanowisko polityczne (PEP)
              </InputCheckBox>
            </div>
            <InputDropDown
              returnSimpleValue
              label="Stan cywilny"
              :options="setupOptions.maritalStatuses"
              v-model="applicationData.formData.connectedPerson.martial_status"
              :preset="applicationData.formData.connectedPerson.martial_status"
              :error="errors['formPersonalCompany.maritalStatus']"
          />
          <InputDropDown
              v-if="!lastVersionFormData.disableSpouse"
              returnSimpleValue
              label="Rozdzielność majątkowa"
              :options="yesno"
              v-model="applicationData.formData.connectedPerson.separate_property"
              :preset="applicationData.formData.connectedPerson.separate_property"
              v-show="applicationData.formData.connectedPerson.martial_status === 'MARRIED'"
          />
          </div>
          <Accordion v-if="!lastVersionFormData.disableSpouse" :expanded="applicationData.formData.connectedPerson.martial_status === 'MARRIED' && !applicationData.formData.connectedPerson.separate_property">
            <div class="pb-4 pt-8 font-extralight">Dane osobowe współmałżonka</div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputText label="Imię" v-model="applicationData.formData.spousePerson.first_name" :error="errors['formPersonalCompanySpouse.name']" />
              <InputText label="Nazwisko" v-model="applicationData.formData.spousePerson.last_name" :error="errors['formPersonalCompanySpouse.surname']" />
              <InputText label="Seria i numer dowodu osobistego" v-model="applicationData.formData.spousePerson.document_number" :error="errors['formPersonalCompanySpouse.documentNumber']" />
              <InputText label="Wydany przez" v-model="applicationData.formData.spousePerson.document_authority" :error="errors['formPersonalCompanySpouse.documentAuthority']" />
              <InputText :mask="['####-##-##', 'YYYY-MM-DD']" label="Data ważności dowodu osobistego" v-model="applicationData.formData.spousePerson.document_expiration_date" :error="errors['formPersonalCompanySpouse.documentExpiration']" />
              <div>
                <InputCheckBox @change="(v) => applicationData.formData.spousePerson.document_indefinitely = v" :checked="applicationData.formData.spousePerson.document_indefinitely">

                  Posiada dowód ważny bezterminowo
                </InputCheckBox>
              </div>
              <InputText label="PESEL" v-model="applicationData.formData.spousePerson.pesel" :error="errors['formPersonalCompanySpouse.pesel']" />
              <div>
                <InputCheckBox @change="(v) => applicationData.formData.spousePerson.politically_exposed = v"
                              :checked="applicationData.formData.spousePerson.politically_exposed">Zajmuje eksponowane stanowisko polityczne (PEP)
                </InputCheckBox>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
      <div v-if="lastVersionFormData.isUploadEnabled || isUploadEnabled">
        <div class="pb-2 pt-8 font-extralight">Dołącz dokumenty</div>
        <p class="text-xs font-extralight">Jeśli są wymagane do złożenia wniosku.</p>
        <InputUpload :isLoggedAsOperator="isLoggedAsOperator" :initial-files="files" @update:files="handleFilesUpdate" />
      </div>
      <TransactionFormLiczakStatements
        v-model="applicationData.formData.agreements"
        :agreementAdded="agreementAdded"
        :errors="errors['agreements']"
      />
      <div class="pt-6 flex justify-end mt-8">
        <ButtonRounded :disabled="!allAgreementsChecked" class="max-w-[192px]" @click="submit">
            <template v-if="formLoading">
                <EffectSpinLoader class="!w-5 !h-5" />
            </template>
            <template v-else>Wyślij</template>
        </ButtonRounded>
      </div>
      <p v-if="showMessage" 
        :class="{'text-error-red': formHasErrors, 'text-grass-green': formSaved}"
        class="text-center">
        <span v-if="!allAgreementsChecked">Wszystkie zgody muszą być zaznaczone.</span>
        <span class="text-error-red" v-else-if="formHasErrors">
          Formularz zawiera błędy i nie został zapisany.
        </span>
        <span class="text-grass-green" v-else-if="formSaved">
          Dane formularza zostały zapisane.
        </span>
      </p>
    </div>
  </div>
</template>
