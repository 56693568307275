
<script setup>
  import { useFileDialog } from '@vueuse/core'

  const backend = useBackend();
  const route = useRoute();

  const props = defineProps({
    initialFiles: {
      type: Array,
      default: () => [],
    },
    isLoggedAsOperator: {
      type: Boolean,
      default: () => false
    }
  });
  const isLoggedLocally = ref(false);
  const emit = defineEmits(['update:files'])
  const acceptedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'webp'];
  const errorMessage = ref('');
  const { open, onChange } = useFileDialog({
    accept: '.pdf,.jpg,.jpeg,.png,.webp',
    multiple: true,
  })

  const selectedFiles = ref([...props.initialFiles]);
  watch(
    () => props.initialFiles,
    (newFiles) => {
      selectedFiles.value = [...newFiles];
    },
    { immediate: true }
  );
  watch(
    () => props.isLoggedAsOperator,
    (newVal) => {
      isLoggedLocally.value = newVal;
    },
    { immediate: true }
  );
  onChange((fileList) => {
    if (fileList) {
      const validFiles = [];
      for (const file of fileList) {
        const extension = file.name.split('.').pop().toLowerCase();
        if (acceptedExtensions.includes(extension)) {
          validFiles.push(file);
        } else {
          errorMessage.value = `Nieprawidłowy format pliku: ${file.name}`;
        }
      }
      selectedFiles.value = selectedFiles.value.concat(validFiles);
    }
  });

  function handleDrop(event) {
    event.preventDefault();
    event.currentTarget.classList.remove('drag-over');

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const validFiles = [];
      for (const file of droppedFiles) {
        const extension = file.name.split('.').pop().toLowerCase();
        if (acceptedExtensions.includes(extension)) {
          validFiles.push(file);
        } else {
          errorMessage.value = `Nieprawidłowy format pliku: ${file.name}`;
        }
      }
      selectedFiles.value = selectedFiles.value.concat(validFiles);
    }
  }

  const removeFile = async (index) => {
    try {
      const formData = new FormData();
      formData.append('filename', selectedFiles.value[index].name);
      await backend.deleteFileLiczakOffer(route.params.uuid, formData);
      selectedFiles.value.splice(index, 1)
      } catch (error) {
        console.error('Wystąpił błąd:', error)
      }
  }

  watch(selectedFiles, () => {
    emit('update:files', selectedFiles.value)
  })

  const fileExtensions = computed(() => {
    return selectedFiles.value.map(file => {
      const extension = file.name.split('.').pop().toLowerCase()
      return {
        file,
        extension,
        isPdf: extension === 'pdf',
      }
    })
  })
</script>

<template>
    <div class="cursor-pointer">
      <div
        class="mt-6 flex flex-col items-center justify-center border-2 border-dashed border-coolGray-700 rounded-lg p-6 w-full h-[132px]"
        @click="open"
        @dragover.prevent
        @drop.prevent="handleDrop"
      >
        <div class="flex items-center justify-center text-coolGray-700 rounded-full">
          <VectorUpload class="h-10 w-10" />
        </div>
        <p class="text-coolGray-700 mt-2 text-base font-normal">Kliknij lub upuść pliki</p>
      </div>
      <div v-if="selectedFiles.length > 0" class="mt-4">
        <ul>
        <li
          v-for="(fileInfo, index) in fileExtensions"
          :key="fileInfo.file.name + index"
          class="flex items-center mb-2"
        >
          <div class="flex items-center">
            <span class="inline-block mr-2" v-if="fileInfo.isPdf"><VectorPdf/></span>
            <span class="inline-block mr-2" v-else><VectorImage/></span>
            {{ fileInfo.file.name }}
          </div>
          <button v-if="isLoggedAsOperator" @click="removeFile(index)" class="ml-2 text-xs text-red-500">X</button>
        </li>
      </ul>
      </div>
    </div>
    <div v-if="errorMessage" class="text-red-500 mt-2">
  {{ errorMessage }}
</div>
  </template>

