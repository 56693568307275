<template>
    
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0797 46.6081C11.7277 46.6081 1.67969 36.5601 1.67969 24.2081C1.67969 11.8561 11.7277 1.80811 24.0797 1.80811C36.4317 1.80811 46.4797 11.8561 46.4797 24.2081C46.4797 36.5601 36.4317 46.6081 24.0797 46.6081ZM24.0797 2.60811C12.1597 2.60811 2.47969 12.3041 2.47969 24.2081C2.47969 36.1121 12.1597 45.8081 24.0797 45.8081C35.9997 45.8081 45.6797 36.1281 45.6797 24.2081C45.6797 12.2881 35.9837 2.60811 24.0797 2.60811Z" fill="#3B61AB"/>
<path d="M24.0793 44.2562C35.1515 44.2562 44.1273 35.2804 44.1273 24.2082C44.1273 13.136 35.1515 4.16016 24.0793 4.16016C13.007 4.16016 4.03125 13.136 4.03125 24.2082C4.03125 35.2804 13.007 44.2562 24.0793 44.2562Z" fill="url(#paint0_linear_8713_306701)"/>
<path d="M30.0619 38.56H17.8219C16.9259 38.56 16.2539 37.76 16.2539 36.784V11.632C16.2539 10.656 16.9419 9.85596 17.8219 9.85596H30.0619C30.9579 9.85596 31.6299 10.656 31.6299 11.632V36.8C31.6299 37.776 30.9419 38.576 30.0619 38.576V38.56Z" stroke="white" stroke-miterlimit="10"/>
<path d="M16.207 13.8882H31.647" stroke="white" stroke-linejoin="round"/>
<path d="M16.207 33.8882H31.647" stroke="white" stroke-linejoin="round"/>
<path d="M22.5117 11.792H25.3437" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.4131 36.1438C24.4131 36.3838 24.2051 36.5918 23.9331 36.5918C23.6611 36.5918 23.4531 36.3838 23.4531 36.1438C23.4531 35.9038 23.6611 35.6958 23.9331 35.6958C24.2051 35.6958 24.4131 35.9038 24.4131 36.1438Z" stroke="white" stroke-linejoin="round"/>
<path d="M25.6641 26.8481L28.8641 30.3681" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.2773 25.0718L29.0533 27.0878" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.2465 24.96C28.2545 23.184 28.0145 20.88 26.5265 19.392C25.0385 17.904 23.5025 17.776 21.8865 18.224L24.3985 20.736V22.4L22.9905 23.808H21.3265L18.8145 21.296C18.3345 22.864 18.6865 24.688 19.9825 25.936C21.4705 27.424 23.7745 27.712 25.5505 26.656" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_8713_306701" x1="4.03125" y1="24.2082" x2="44.1113" y2="24.2082" gradientUnits="userSpaceOnUse">
<stop stop-color="#65B1FF"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

</template>