<template>
    <svg  width="253" height="40" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1580.4 202.6" style="enable-background:new 0 0 1580.4 202.6;" xml:space="preserve">
    <g>
        <path d="M637.1,22.2c-7.7,0-14,6-14,14.1v69.2c0,0.2,0,0.5,0,0.7v11.1c0,10-3.4,18-9.6,25.2c0,0-0.1,0.1-0.1,0.1
            c-4.5,5.1-10.7,7.9-16.9,8.3c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.8,0
            c-0.1,0-0.1,0-0.2,0c-6.3-0.4-12.4-3.2-16.9-8.3c0,0-0.1-0.1-0.1-0.1c-6.2-7.3-9.6-15.2-9.6-25.2v-11.1c0-0.2,0-0.5,0-0.7V36.4
            c0-8.1-6.3-14.1-14-14.1c-7.8,0-14.1,5.9-14.1,14.1v69.9v10.5c0,0.6,0,1.1,0,1.7c0,0.2,0,0.4,0,0.6c0,0.4,0,0.7,0,1.1
            c0,0.2,0,0.4,0,0.6c0,0.3,0,0.7,0.1,1c0,0.2,0,0.4,0.1,0.7c0,0.3,0,0.6,0.1,1c0,0.2,0,0.5,0.1,0.7c0,0.3,0.1,0.6,0.1,0.9
            c0,0.2,0.1,0.5,0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0.1,0.5,0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0.1,0.5,0.1,0.7
            c0.1,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.1,0.6,0.2,0.9
            c0,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.4,0.2,0.6
            c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.3,0.2,0.6,0.3,0.9
            c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.3,0.2,0.6,0.4,1c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.3,0.3,0.6,0.4,1c0.1,0.1,0.1,0.3,0.2,0.4
            c0.1,0.3,0.3,0.7,0.4,1c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.3,0.3,0.7,0.5,1c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.3,0.3,0.7,0.5,1
            c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.4,0.7,0.6,1c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.4,0.7,0.6,1.1c0,0.1,0.1,0.2,0.1,0.2
            c0.2,0.4,0.4,0.7,0.7,1.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.4,0.5,0.8,0.7,1.2c0,0,0.1,0.1,0.1,0.1c0.3,0.4,0.5,0.8,0.8,1.2
            c0,0,0,0,0,0.1c2.1,3,4.4,5.8,7,8.6c10.3,10.8,23.9,16.2,37.6,16.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
            c13.7-0.1,27.4-5.5,37.6-16.3c2.6-2.7,4.9-5.6,7-8.6c0,0,0,0,0-0.1c0.3-0.4,0.5-0.8,0.8-1.2c0,0,0.1-0.1,0.1-0.1
            c0.2-0.4,0.5-0.8,0.7-1.1c0-0.1,0.1-0.1,0.1-0.2c0.2-0.4,0.4-0.7,0.7-1.1c0-0.1,0.1-0.2,0.1-0.2c0.2-0.4,0.4-0.7,0.6-1.1
            c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.7,0.6-1c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.7,0.5-1c0.1-0.1,0.1-0.2,0.2-0.4
            c0.2-0.3,0.3-0.7,0.5-1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.3-0.7,0.4-1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.3-0.6,0.4-1
            c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.2-0.6,0.4-1c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.2,0.1-0.4,0.2-0.5
            c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.6,0.3-0.9
            c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.4,0.1-0.6
            c0.1-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.4,0.1-0.7c0-0.3,0.1-0.6,0.1-0.9
            c0-0.2,0.1-0.4,0.1-0.7c0-0.3,0.1-0.6,0.1-0.9c0-0.2,0.1-0.5,0.1-0.7c0-0.3,0.1-0.6,0.1-0.9c0-0.2,0-0.5,0.1-0.7
            c0-0.3,0.1-0.6,0.1-1c0-0.2,0-0.4,0.1-0.7c0-0.3,0-0.7,0.1-1c0-0.2,0-0.4,0-0.6c0-0.4,0-0.7,0-1.1c0-0.2,0-0.4,0-0.6
            c0-0.6,0-1.1,0-1.7v-10.5V36.4C651.2,28.2,644.9,22.2,637.1,22.2z"/>
        <radialGradient id="SVGID_1_" cx="1307.7244" cy="163.2827" r="14.847" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style="stop-color:#0668A9"/>
            <stop  offset="1" style="stop-color:#00406F"/>
        </radialGradient>
        <path class="st0" d="M1307.7,178.1c8.2,0,14.8-6.6,14.8-14.8c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8
            C1292.9,171.5,1299.5,178.1,1307.7,178.1z"/>
        <path d="M1134.2,42c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v-4.5c0-5.4-6.3-9.1-13.5-9.1c-7.4,0-13.7,3.8-13.7,9.1v2.6
            c-0.1,0.7-0.2,1.3-0.2,2c0,0.7,0.1,1.3,0.2,2v39c-0.1,0.7-0.2,1.4-0.2,2.1v41c0,7.8-2.7,14.4-8.1,19.8c-5.4,5.4-12,8.1-19.6,8.1
            c-3.9,0-7.2-0.5-12.3-2.8c-0.1,0.4-0.1,0.3-0.2,0.5c-2.2-1.4-4.6-2.4-7.3-2.4c-7.6,0-13.7,6.1-13.7,13.7c0,5.3,3.1,9.8,7.6,12.1v0
            l0,0c0,0,0.1,0,0.2,0.1c0.5,0.3,1.1,0.4,1.7,0.6c8.5,4,16,5.4,24.1,5.4c15.1,0,28.1-5.4,38.8-16.1c10.8-10.8,16.1-23.8,16.1-39
            v-2.9c0.1-0.5,0.1-0.9,0.1-1.4V42z"/>
        <g>
            <path d="M413.8,22c-7.6,0-14.1,5.8-14.1,14v129.3c0,8,6.2,14.1,14.1,14.1c7.6,0,14-6,14-14.1V36C427.7,27.7,421.2,22,413.8,22z"/>
            <path d="M515.2,157.8c0.1,0,0.5,0.5-51.1-51.1c-3.2-3.2-3.2-8.4,0-11.6L514.2,45c5.3-5.3,5.3-13.9,0-19.2
                c-5.3-5.3-13.9-5.3-19.2,0l-50.1,50.1c-13.8,13.8-13.8,36.3,0,50.1l51.1,51.1c5.3,5.3,13.9,5.3,19.2,0
                C520.5,171.8,520.5,163.1,515.2,157.8z"/>
        </g>
        <path d="M751.4,149.9h-45.2V37.3c0-3.9-1.4-7.2-4.1-9.9c-2.8-2.8-6.1-4.2-9.9-4.2c-3.9,0-7.3,1.4-10.1,4.2c-2.7,2.7-4.1,6-4.1,9.9
            v126.6c0,8,6.2,14.1,14.1,14.1h59.2c3.9,0,7.2-1.4,9.9-4.1c2.8-2.8,4.2-6.2,4.2-10.1c0-3.8-1.4-7.1-4.2-9.9
            C758.6,151.2,755.3,149.9,751.4,149.9z"/>
        <path d="M902.4,74.5c0-5.9-0.9-11.7-2.7-17.4c-0.3-1-0.4-1.2-0.2-0.8C893.3,38,881,26,862.5,20.2c-0.1,0,0.4,0-5.1-1.3h-0.2
            c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.5-0.2-0.6-0.2c-1.4-0.3-2.7-0.6-4.5-0.8c-0.5-0.1-0.9-0.1-1.2-0.2c-0.6,0-1.1,0-1.6-0.1
            c-1.2-0.1-2.5-0.1-4.2-0.1c-1.6,0-3,0-4.2,0.1c-0.5,0-1,0-1.6,0.1c-0.2,0-0.6,0.1-1.2,0.2c-1.7,0.2-3.1,0.5-4.5,0.8
            c-0.1,0-0.3,0-0.6,0.2c-0.1,0-0.2,0-0.3,0h-0.2c-5.5,1.4-5,1.3-5.1,1.3C809,26,796.7,38,790.5,56.3c0.2-0.5,0.1-0.3-0.2,0.8
            c-1.8,5.7-2.7,11.5-2.7,17.4v44.3c0,0.4,0,0.9,0.1,1.3c0,51-0.1,38.9-0.1,40.8c0,2,0.4,3.8,1.1,5.5c0.1,0.2,0.3,0.4,0.4,0.7
            c2.6,5.4,7.2,8,12.6,8c3.9,0,7.2-1.4,9.9-4.1c1-1,1.6-2.2,2.3-3.4c1.1-2,1.9-4.2,1.9-6.7c0-0.2-0.1-0.4-0.1-0.7v-41.4h58.4v41.4
            c0,0.2-0.1,0.4-0.1,0.7c0,2.5,0.8,4.7,1.9,6.7c0.6,1.2,1.2,2.4,2.3,3.4c2.7,2.7,6,4.1,9.9,4.1c5.4,0,10-2.7,12.6-8
            c0.1-0.2,0.4-0.4,0.4-0.7c0.7-1.7,1.1-3.6,1.1-5.5c0-1.9,0,10.2-0.1-40.8c0-0.4,0.1-0.9,0.1-1.3V74.5z M815.8,90.7V74.5
            c0-10.1,5.7-19.9,14.6-25c1.9-0.9,3.5-1.7,5.4-2.4c0,0,0,0,0,0c0.1,0,0.2-0.1,0.4-0.1c1.2-0.4,2.3-0.6,3.5-0.8c0.2,0,0.6-0.1,1-0.1
            c1.5-0.2,2.9-0.3,4.4-0.3c1.5,0,2.9,0.1,4.4,0.3c0.4,0.1,0.7,0.1,1,0.1c1.2,0.2,2.4,0.5,3.5,0.8c0.1,0,0.2,0.1,0.4,0.1c0,0,0,0,0,0
            c1.9,0.6,3.5,1.5,5.4,2.4c8.9,5.1,14.6,14.9,14.6,25v16.2H815.8z"/>
        <path d="M1030.9,133.2c-1.3-6.4-6.8-11.3-13.6-11.3c-2.6,0-4.9,0.9-7.1,2.2c-1.5,0.8-2.4,1.6-3.6,2.9c-0.8,1-2.1,3.1-2.7,4.8
            c-1.9,5.7-5.3,10.3-10.3,13.9c-5,3.7-9.9,5.2-15.3,5.5c-0.6-0.1-1.2-0.1-1.9-0.1c-0.2,0-0.3,0.1-0.5,0.1c-8,0-14.8-2.8-20.4-8.4
            c-4.6-4.6-7.3-10-8.2-16.2c-0.2-1.3-0.3-2.7-0.3-4.1V78.8c0-1.4,0.1-2.8,0.3-4.1c0.8-6.2,3.5-11.6,8.2-16.2
            C961.2,52.8,968,50,976,50c0.2,0,0.4,0.1,0.5,0.1c0.6,0,1.3,0,1.9-0.1c5.5,0.3,10.3,1.8,15.3,5.5c5,3.6,8.4,8.2,10.3,13.9
            c0.5,1.6,1.8,3.8,2.7,4.8c1.2,1.3,2,2.1,3.6,2.9c2.1,1.2,4.4,2.2,7.1,2.2c6.8,0,12.2-4.9,13.6-11.3c0.1-0.5,0.3-1.1,0.3-1.7
            c0-0.4,0.2-0.8,0.2-1.2c0-1.2-0.4-2.3-0.7-3.4c-0.1-0.4,0-0.8-0.1-1.3c0-0.1-0.5-1.1-0.5-1.1c-3.9-10.8-10.5-19.7-20-26.5
            c-9.9-7.3-21-11-33.4-11c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-15.9,0.1-29.2,5.1-40.8,16.7c-9.1,9.1-14.5,19.8-16.1,32
            c-0.4,2.7-0.5,5.4-0.5,8.3v0v43.5v0c0,2.8,0.2,5.6,0.5,8.3c1.6,12.2,7,22.9,16.1,32c11.6,11.6,24.9,16.6,40.8,16.7c0,0,0,0,0,0
            c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c12.3,0,23.4-3.7,33.4-11c9.5-6.9,16.1-15.7,20-26.5c0-0.1,0.5-1,0.5-1.1c0.1-0.4,0-0.8,0.1-1.3
            c0.3-1.1,0.7-2.2,0.7-3.4c0-0.4-0.2-0.8-0.2-1.2C1031.2,134.3,1031,133.7,1030.9,133.2z"/>
        <path d="M1263.5,78.7c0-5.9-0.9-11.7-2.7-17.4c-0.3-1-0.4-1.2-0.2-0.8c-6.2-18.3-18.5-30.4-37-36.1c-0.1,0,0.4,0-5.1-1.3h-0.2
            c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.5-0.2-0.6-0.2c-1.4-0.3-2.7-0.6-4.5-0.8c-0.5-0.1-0.9-0.1-1.2-0.2c-0.6,0-1.1,0-1.6-0.1
            c-1.2-0.1-2.5-0.1-4.2-0.1c-1.6,0-3,0-4.2,0.1c-0.5,0-1,0-1.6,0.1c-0.2,0-0.6,0.1-1.2,0.2c-1.7,0.2-3.1,0.5-4.5,0.8
            c-0.1,0-0.3,0-0.6,0.2c-0.1,0-0.2,0-0.3,0h-0.2c-5.5,1.4-5,1.3-5.1,1.3c-18.4,5.7-30.8,17.7-37,36.1c0.2-0.5,0.1-0.3-0.2,0.8
            c-1.8,5.7-2.7,11.5-2.7,17.4V123c0,0.4,0,0.9,0.1,1.3c0,51-0.1,38.9-0.1,40.8c0,2,0.4,3.8,1.1,5.5c0.1,0.2,0.3,0.4,0.4,0.7
            c2.6,5.4,7.2,8,12.6,8c3.9,0,7.2-1.4,9.9-4.1c1-1,1.6-2.2,2.3-3.4c1.1-2,1.9-4.2,1.9-6.7c0-0.2-0.1-0.4-0.1-0.7V123h58.4v41.4
            c0,0.2-0.1,0.4-0.1,0.7c0,2.5,0.8,4.7,1.9,6.7c0.6,1.2,1.2,2.4,2.3,3.4c2.7,2.7,6,4.1,9.9,4.1c5.4,0,10-2.7,12.6-8
            c0.1-0.2,0.4-0.4,0.4-0.7c0.7-1.7,1.1-3.6,1.1-5.5c0-1.9,0,10.2-0.1-40.8c0-0.4,0.1-0.9,0.1-1.3V78.7z M1176.9,94.9V78.7
            c0-10.1,5.7-19.9,14.6-25c1.9-0.9,3.5-1.7,5.4-2.4c0,0,0,0,0,0c0.1,0,0.2-0.1,0.4-0.1c1.2-0.4,2.3-0.6,3.5-0.8c0.2,0,0.6-0.1,1-0.1
            c1.5-0.2,2.9-0.3,4.4-0.3c1.5,0,2.9,0.1,4.4,0.3c0.4,0.1,0.7,0.1,1,0.1c1.2,0.2,2.4,0.5,3.5,0.8c0.1,0,0.2,0.1,0.4,0.1c0,0,0,0,0,0
            c1.9,0.6,3.5,1.5,5.4,2.4c8.9,5.1,14.6,14.9,14.6,25v16.2H1176.9z"/>
        <path d="M1401.4,23.4h-5.6c-0.2,0-0.3-0.1-0.5-0.1c-7.9,0-14.3,6.4-14.3,14.3c0,7.9,6.4,14.3,14.3,14.3c0.2,0,0.3-0.1,0.5-0.1h5.6
            c8,0,14.8,2.8,20.5,8.5c5.8,5.7,8.6,12.5,8.6,20.5v0.9c0,8.1-2.9,15-8.6,20.6c-5.7,5.7-12.5,8.5-20.5,8.5h-41.2
            c-10.7,0-19.4,8.7-19.4,19.4v35.3c0,8.2,6.2,14.2,14.2,14.2c7.8,0,14.1-6,14.1-14.2v-22.4c0-2.2,1.8-4,4-4h28.3
            c15.9,0,29.4-5.6,40.6-16.8c11.2-11.2,16.8-24.7,16.8-40.6c0-16-4.9-29.5-16.8-41.4C1430.7,28.9,1417.2,23.4,1401.4,23.4z"/>
        <path d="M1548.1,150.7h-45.2V38.1c0-3.9-1.4-7.2-4.1-9.9c-2.8-2.8-6.1-4.2-9.9-4.2c-3.9,0-7.3,1.4-10.1,4.2c-2.7,2.7-4.1,6-4.1,9.9
            v126.6c0,8,6.2,14.1,14.1,14.1h59.2c3.9,0,7.2-1.4,9.9-4.1c2.8-2.8,4.2-6.2,4.2-10.1c0-3.8-1.4-7.1-4.2-9.9
            C1555.3,152,1552,150.7,1548.1,150.7z"/>
        <g>
            
                <radialGradient id="SVGID_00000139296081161103536950000005723573964774794368_" cx="318.8291" cy="102.0082" r="65.8106" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style="stop-color:#0668A9"/>
                <stop  offset="1" style="stop-color:#00406F"/>
            </radialGradient>
            <path style="fill:url(#SVGID_00000139296081161103536950000005723573964774794368_);" d="M350.6,125.2
                c13.1-11.3,19.7-25.6,19.7-42.8c0-16-5-29.4-16.7-41c-11.1-11.1-24.6-16.7-40.3-16.7h-30.8c-0.3,0-0.6-0.2-0.9-0.2
                c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.2,14.1,14.2c3.2,0-11.7,0,31.7,0c7.9,0,14.7,2.8,20.3,8.4c5.7,5.6,8.6,12.4,8.6,20.3v0.9
                c0,8-2.8,14.8-8.6,20.4c-5.6,5.6-12.4,8.4-20.3,8.4h-12.4c-0.2,0-0.3-0.1-0.5-0.1c-7.8,0-14.1,6.3-14.1,14.2
                c0,7.8,6.3,14.1,14.1,14.1c0.2,0,0.3-0.1,0.5-0.1h12.5c7.9,0,14.7,2.8,20.3,8.4c5.7,5.6,8.6,12.4,8.6,20.3v-2.6
                c0,7.7,5.8,14.1,14,14.1c7.6,0,13.5-5.7,14-13.3C369.7,150,363.2,136.2,350.6,125.2z"/>
            
                <radialGradient id="SVGID_00000114792325114589273950000005092517513796381592_" cx="201.5423" cy="102.0006" r="68.8688" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style="stop-color:#0668A9"/>
                <stop  offset="1" style="stop-color:#00406F"/>
            </radialGradient>
            <path style="fill:url(#SVGID_00000114792325114589273950000005092517513796381592_);" d="M256.1,61.9c-6.2-18.3-18.5-30.4-37-36.1
                c-0.1,0,0.4,0-5.1-1.3h-0.2c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.5-0.2-0.6-0.2c-1.4-0.3-2.7-0.6-4.5-0.8c-0.5-0.1-0.9-0.1-1.2-0.2
                c-0.6,0-1.1,0-1.6-0.1c-1.2-0.1-2.5-0.1-4.2-0.1c-1.6,0-3,0-4.2,0.1c-0.5,0-1,0-1.6,0.1c-0.2,0-0.6,0.1-1.2,0.2
                c-1.7,0.2-3.1,0.5-4.5,0.8c-0.1,0-0.3,0-0.6,0.2c-0.1,0-0.2,0-0.3,0h-0.2c-5.5,1.4-5,1.3-5.1,1.3c-18.4,5.7-30.8,17.7-37,36.1
                c0.2-0.5,0.1-0.3-0.2,0.8c-1.8,5.7-2.7,11.5-2.7,17.4v44.3c0,0.4,0,0.9,0.1,1.3c0,51-0.1,38.9-0.1,40.8c0,2,0.4,3.8,1.1,5.5
                c0.1,0.2,0.3,0.4,0.4,0.7c2.6,5.4,7.2,8,12.6,8c3.9,0,7.2-1.4,9.9-4.1c1-1,1.6-2.2,2.3-3.4c1.1-2,1.9-4.2,1.9-6.7
                c0-0.2-0.1-0.4-0.1-0.7v-41.4h58.4v41.4c0,0.2-0.1,0.4-0.1,0.7c0,2.5,0.8,4.7,1.9,6.7c0.6,1.2,1.2,2.4,2.3,3.4
                c2.7,2.7,6,4.1,9.9,4.1c5.4,0,10-2.7,12.6-8c0.1-0.2,0.4-0.4,0.4-0.7c0.7-1.7,1.1-3.6,1.1-5.5c0-1.9,0,10.2-0.1-40.8
                c0-0.4,0.1-0.9,0.1-1.3V80.1c0-5.9-0.9-11.7-2.7-17.4C255.9,61.7,255.9,61.4,256.1,61.9z M230.7,96.3h-58.4V80.1
                c0-10.1,5.7-19.9,14.6-25c1.9-0.9,3.5-1.7,5.4-2.4c0,0,0,0,0,0c0.1,0,0.2-0.1,0.4-0.1c1.2-0.4,2.3-0.6,3.5-0.8
                c0.2,0,0.6-0.1,1-0.1c1.5-0.2,2.9-0.3,4.4-0.3c1.5,0,2.9,0.1,4.4,0.3c0.4,0.1,0.7,0.1,1,0.1c1.2,0.2,2.4,0.5,3.5,0.8
                c0.1,0,0.2,0.1,0.4,0.1c0,0,0,0,0,0c1.9,0.6,3.5,1.5,5.4,2.4c8.9,5.1,14.6,14.9,14.6,25V96.3z"/>
            
                <radialGradient id="SVGID_00000149365404437087506410000008219819528534124213_" cx="71.2244" cy="101.6669" r="68.4164" gradientUnits="userSpaceOnUse">
                <stop  offset="0" style="stop-color:#0668A9"/>
                <stop  offset="1" style="stop-color:#00406F"/>
            </radialGradient>
            <path style="fill:url(#SVGID_00000149365404437087506410000008219819528534124213_);" d="M43.4,75.8c0.8-6.2,3.5-11.6,8.2-16.2
                c5.6-5.6,12.4-8.4,20.4-8.4c0.2,0,0.4,0.1,0.5,0.1c0.6,0,1.3,0,1.9-0.1c5.5,0.3,10.3,1.8,15.3,5.5c5,3.6,8.4,8.2,10.3,13.9
                c0.5,1.6,1.8,3.8,2.7,4.8c1.2,1.3,2,2.1,3.6,2.9c2.1,1.2,4.4,2.2,7.1,2.2c6.8,0,12.2-4.9,13.6-11.3c0.1-0.5,0.3-1.1,0.3-1.7
                c0-0.4,0.2-0.8,0.2-1.2c0-1.2-0.4-2.3-0.7-3.4c-0.1-0.4,0-0.8-0.1-1.3c0-0.1-0.5-1.1-0.5-1.1c-3.9-10.8-10.5-19.7-20-26.5
                c-9.9-7.3-21-11-33.4-11c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C56.5,23,43.3,28,31.7,39.6c-9.1,9.1-14.5,19.8-16.1,32
                C15.2,74.3,15,77,15,79.8v0v43.5v0c0,2.8,0.2,5.6,0.5,8.3c1.6,12.2,7,22.9,16.1,32c11.6,11.6,24.9,16.6,40.8,16.7c0,0,0,0,0,0
                c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c12.3,0,23.4-3.7,33.4-11c9.5-6.9,16.1-15.7,20-26.5c0-0.1,0.5-1,0.5-1.1
                c0.1-0.4,0-0.8,0.1-1.3c0.3-1.1,0.7-2.2,0.7-3.4c0-0.4-0.2-0.8-0.2-1.2c-0.1-0.6-0.2-1.1-0.3-1.7c-1.3-6.4-6.8-11.3-13.6-11.3
                c-2.6,0-4.9,0.9-7.1,2.2c-1.5,0.8-2.4,1.6-3.6,2.9c-0.8,1-2.1,3.1-2.7,4.8c-1.9,5.7-5.3,10.3-10.3,13.9c-5,3.7-9.9,5.2-15.3,5.5
                c-0.6-0.1-1.2-0.1-1.9-0.1c-0.2,0-0.3,0.1-0.5,0.1c-8,0-14.8-2.8-20.4-8.4c-4.6-4.6-7.3-10-8.2-16.2c-0.2-1.3-0.3-2.7-0.3-4.1
                V79.9C43.1,78.5,43.2,77.1,43.4,75.8z"/>
        </g>
    </g>
    </svg>
</template>