<template>
    

    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0797 46.6081C11.7277 46.6081 1.67969 36.5601 1.67969 24.2081C1.67969 11.8561 11.7277 1.80811 24.0797 1.80811C36.4317 1.80811 46.4797 11.8561 46.4797 24.2081C46.4797 36.5601 36.4317 46.6081 24.0797 46.6081ZM24.0797 2.60811C12.1597 2.60811 2.47969 12.3041 2.47969 24.2081C2.47969 36.1121 12.1597 45.8081 24.0797 45.8081C35.9997 45.8081 45.6797 36.1281 45.6797 24.2081C45.6797 12.2881 35.9837 2.60811 24.0797 2.60811Z" fill="#3B61AB"/>
<path d="M24.0793 44.2562C35.1515 44.2562 44.1273 35.2804 44.1273 24.2082C44.1273 13.136 35.1515 4.16016 24.0793 4.16016C13.007 4.16016 4.03125 13.136 4.03125 24.2082C4.03125 35.2804 13.007 44.2562 24.0793 44.2562Z" fill="url(#paint0_linear_8713_306857)"/>
<path d="M11.3086 21.4082H14.5406C15.6926 21.4082 16.8446 21.7602 17.8206 22.3842L23.4686 26.1922C23.7566 26.3682 24.0766 26.5122 24.3966 26.5442" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M10.4141 21.4082H14.2221" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M24.4141 26.5601L27.9821 27.1041C29.1661 27.2801 30.2861 27.8561 30.9741 28.8321C31.3741 29.3761 31.5821 29.9841 31.5821 30.5921V30.9921C31.5821 32.2561 30.5741 33.2641 29.3101 33.2641H28.8461" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M10.5898 33.2803H18.6218" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M10.5898 26.48L24.4138 26.56" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M26.8942 33.2803C26.8942 34.9283 25.5662 36.2723 23.9022 36.2723C22.2382 36.2723 20.9102 34.9443 20.9102 33.2803" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M26.8942 33.2801C26.8942 31.6321 25.5662 30.2881 23.9022 30.2881C22.2382 30.2881 20.9102 31.6161 20.9102 33.2801" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M12.3164 21.4082V26.4802" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M28.6867 24.0802C28.1427 24.1442 27.6147 24.1442 27.1347 24.0802C25.0867 23.7282 23.4227 22.0322 23.1027 19.9842C23.0387 19.4882 23.0387 19.0082 23.1027 18.5122" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.767 20.8001C28.559 20.8321 28.303 20.8321 28.127 20.8001C27.663 20.7361 27.295 20.5121 26.975 20.1921C26.687 19.8721 26.479 19.4721 26.399 19.0721C26.367 18.8641 26.367 18.6401 26.399 18.4321" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.5437 22.4963C28.2877 22.5283 28.0477 22.5283 27.7917 22.4963C26.9597 22.3843 26.2397 22.0002 25.7117 21.4242C25.1677 20.8482 24.7837 20.0962 24.7037 19.3762C24.6717 19.1202 24.6717 18.9122 24.7037 18.6562" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.2469 13.936L28.6549 17.248C28.5109 17.424 28.5109 17.648 28.6549 17.792L29.3749 18.512C29.5189 18.656 29.7269 18.656 29.9189 18.512L33.2309 15.92C33.4069 15.776 33.3749 15.52 33.2309 15.376L31.7589 13.904C31.6469 13.792 31.4069 13.76 31.2629 13.936H31.2469Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.8521 9.04011L23.4361 11.4561C23.2921 11.6001 23.2921 11.8561 23.4361 12.0001L26.6361 15.2001C26.9241 15.4881 29.0041 15.7441 29.7241 15.8881L30.3641 15.0561C30.2521 14.3041 29.9641 12.5761 29.6121 12.2081L26.4121 9.00811C26.2361 8.89611 25.9801 8.89611 25.8361 9.04011H25.8521Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.1588 21.3442L35.7428 23.7602C35.5988 23.9042 35.3428 23.9042 35.1988 23.7602L31.9668 20.5922C31.6788 20.3042 31.3268 18.2882 31.2148 17.5682L32.0788 16.9282C32.8308 17.0402 34.5908 17.2802 34.9588 17.6482L38.1588 20.8482C38.3028 20.9602 38.3028 21.2002 38.1588 21.3442Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_8713_306857" x1="4.03125" y1="24.2082" x2="44.1113" y2="24.2082" gradientUnits="userSpaceOnUse">
<stop stop-color="#65B1FF"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

</template>