<script setup>
const backend = useBackend();
const route = useRoute();

const emit = defineEmits(['pin-valid']);

const isPinBoxOpened = ref(false);
const typedPin = ref('');
const pinCheckLoading = ref(false);
const isPinValid = ref(false);
const wasPinChecked = ref(false);
const togglePinBoxLoading = ref(false);
const error = ref('');

const checkPin = async () => {
  pinCheckLoading.value = true;
  try {
    const response = await backend.getPinValidationLiczakOffer(route.params.uuid, typedPin.value);

    if (response.status !== 200) {
      error.value = 'Wystąpił błąd. Spróbuj ponownie.';
      throw new Error('PIN nie został znaleziony.');
    }
    isPinValid.value = true;
    wasPinChecked.value = true;
    emit('pin-valid', response?.data);
  } catch (err) {
    error.value = 'Wystąpił błąd. Spróbuj ponownie.';
    console.error('Błąd podczas weryfikacji PIN:', err);
  }
  pinCheckLoading.value = false;
};

const togglePinBox = async () => {
  error.value = '';
  togglePinBoxLoading.value = true;
  try {
    const response = await backend.sendSmsWithPinOnLiczakOffer(route.params.uuid);
    if (response.status !== 200) {
      error.value = 'Wystąpił błąd. Spróbuj ponownie.';
      throw new Error('PIN nie został znaleziony.');
    }
    isPinBoxOpened.value = true;
  }
  catch (err) {
    togglePinBoxLoading.value = false;
    error.value = 'Wystąpił błąd. Spróbuj ponownie.';
    console.error('Błąd podczas weryfikacji PIN:', err);
  }
};

</script>

<template>
  <div class="rounded shadow-box p-6 mb-8">
    <div v-if="!isPinBoxOpened" class="flex flex-col">
      <ButtonRounded
        :color="'azure'"
        :disabled="togglePinBoxLoading"
        @click="togglePinBox"
      >
        <template v-if="togglePinBoxLoading">
          <EffectSpinLoader class="!w-5 !h-5" />
        </template>
        <template v-else>
          Wyślij PIN
        </template>
      </ButtonRounded>
      <p v-if="error" class="text-error-red mt-8">Wystąpił błąd. Spróbuj ponownie</p>
    </div>

    <div v-else>
      <div>
        <p>PIN został wysłany na powiązany z ofertą numer telefonu.</p>
        <p class="font-extralight">Wpisz otrzymany PIN</p>
        <InputText
          :mask="['####', '____']"
          label="PIN"
          class="col-span-2 max-w-[192px] mt-3"
          v-model="typedPin"
          @keyup.enter="checkPin"
          :error="wasPinChecked && !isPinValid ? 'Podany PIN jest nieprawidłowy' : false"
        />
        <ButtonRounded
          :color="'azure'"
          class="mt-6 max-w-[192px]"
          :disabled="pinCheckLoading"
          @click="checkPin"
        >
          <template v-if="pinCheckLoading">
            <EffectSpinLoader class="!w-5 !h-5" />
          </template>
          <template v-else>Wyślij</template>
        </ButtonRounded>
        <p v-if="error" class="text-error-red mt-8">Wystąpił błąd. Spróbuj ponownie</p>
      </div>
    </div>
  </div>
</template>
