<template>
    
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0797 46.6081C11.7277 46.6081 1.67969 36.5601 1.67969 24.2081C1.67969 11.8561 11.7277 1.80811 24.0797 1.80811C36.4317 1.80811 46.4797 11.8561 46.4797 24.2081C46.4797 36.5601 36.4317 46.6081 24.0797 46.6081ZM24.0797 2.60811C12.1597 2.60811 2.47969 12.3041 2.47969 24.2081C2.47969 36.1121 12.1597 45.8081 24.0797 45.8081C35.9997 45.8081 45.6797 36.1281 45.6797 24.2081C45.6797 12.2881 35.9837 2.60811 24.0797 2.60811Z" fill="#3B61AB"/>
<path d="M24.0793 44.2562C35.1515 44.2562 44.1273 35.2804 44.1273 24.2082C44.1273 13.136 35.1515 4.16016 24.0793 4.16016C13.007 4.16016 4.03125 13.136 4.03125 24.2082C4.03125 35.2804 13.007 44.2562 24.0793 44.2562Z" fill="url(#paint0_linear_8713_312987)"/>
<path d="M23.746 32.149L21.3257 37.4592L18.1793 34.028L13.621 34.8858L15.88 27.615M23.262 31.0053L26.005 37.5L28.9497 33.7421L33.6693 34.7632L31.1683 27.615M23.504 14.5439L25.2385 18.7103L29.5548 18.9962L26.1663 21.9781L27.2151 26.2262L23.383 23.8979L19.7122 26.2262L20.7206 21.8147L17.4128 19.0371L21.8501 18.6694L23.504 14.5439ZM23.625 10.5C29.2321 10.5 33.75 15.0749 33.75 20.7526C33.75 26.4304 29.2321 31.0053 23.625 31.0053C18.0179 31.0053 13.5 26.4304 13.5 20.7526C13.5 15.1157 18.0179 10.5 23.625 10.5Z" stroke="white" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_8713_312987" x1="4.03125" y1="24.2082" x2="44.1113" y2="24.2082" gradientUnits="userSpaceOnUse">
<stop stop-color="#65B1FF"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

</template>