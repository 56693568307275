<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24.0797 46.6081C11.7277 46.6081 1.67969 36.5601 1.67969 24.2081C1.67969 11.8561 11.7277 1.80811 24.0797 1.80811C36.4317 1.80811 46.4797 11.8561 46.4797 24.2081C46.4797 36.5601 36.4317 46.6081 24.0797 46.6081ZM24.0797 2.60811C12.1597 2.60811 2.47969 12.3041 2.47969 24.2081C2.47969 36.1121 12.1597 45.8081 24.0797 45.8081C35.9997 45.8081 45.6797 36.1281 45.6797 24.2081C45.6797 12.2881 35.9837 2.60811 24.0797 2.60811Z" fill="#3B61AB"/>
  <path d="M24.0793 44.2562C35.1515 44.2562 44.1273 35.2804 44.1273 24.2082C44.1273 13.136 35.1515 4.16016 24.0793 4.16016C13.007 4.16016 4.03125 13.136 4.03125 24.2082C4.03125 35.2804 13.007 44.2562 24.0793 44.2562Z" fill="url(#paint0_linear_8713_306779)"/>
  <path d="M18.4023 11.4883H21.3463C22.4023 11.4883 23.4423 11.8083 24.2903 12.3683L29.3623 15.8083C29.6183 15.9843 29.8903 16.0963 30.2103 16.1283" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M17.6172 11.4883H21.0572" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M30.2422 16.0962L33.4742 16.5922C34.5782 16.7682 35.5702 17.2642 36.1622 18.1602C36.5462 18.6562 36.6902 19.2162 36.6902 19.7602V20.1442C36.6902 21.2802 35.7622 22.2082 34.6262 22.2082H34.1942" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M17.793 22.1602H24.993" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M17.793 16.0483L30.241 16.0963" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M32.4346 22.1602C32.4346 23.6802 31.2346 24.8482 29.7466 24.8482C28.2586 24.8482 27.0586 23.6482 27.0586 22.1602" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M32.4346 22.1602C32.4346 20.6402 31.2346 19.4722 29.7466 19.4722C28.2586 19.4722 27.0586 20.6722 27.0586 22.1602" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M19.3125 11.4883V16.0483" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M21.0898 33.248H29.7458C30.3858 33.248 30.9938 32.992 31.4418 32.576L36.8658 28.032C37.4738 27.472 37.2498 26.432 36.4818 26.112C35.8098 25.856 35.1378 26.048 34.1778 26.672L29.7138 29.616" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.641 29.9839H27.137C27.745 29.9199 28.161 29.4239 28.193 28.8159C28.193 28.1119 27.697 27.5359 26.993 27.5039C24.577 27.5039 22.881 26.5759 21.041 26.1279C17.777 25.5999 16.177 27.4399 15.793 27.9679" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.8416 30.5758L13.4416 28.9758C13.7616 28.6558 14.3216 28.6558 14.6416 28.9758L18.9296 33.2638C19.2496 33.5838 19.2496 34.1438 18.9296 34.4638L17.3296 36.0638C17.0096 36.3838 16.4496 36.3838 16.1296 36.0638L11.8416 31.7758C11.5216 31.4558 11.5216 30.8958 11.8416 30.5758Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
  <linearGradient id="paint0_linear_8713_306779" x1="4.03125" y1="24.2082" x2="44.1113" y2="24.2082" gradientUnits="userSpaceOnUse">
  <stop stop-color="#65B1FF"/>
  <stop offset="1"/>
  </linearGradient>
  </defs>
  </svg>
 </template>