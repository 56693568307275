<script setup>

</script>

<template>
  <div class="print:shadow-none group relative flex-col w-full
              rounded-lg shadow-box overflow-hidden select-none">
    <slot/>
  </div>
</template>
