<script setup>
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      agreement1: false,
      agreement2: false,
      agreement3: false,
      agreement4: false,
      agreement5: false,
      agreement6: false
    })
  },
  agreementAdded: {
    type: Object,
    default: () => false
  },
  errors: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['update:modelValue']);

const innerValue = ref({
  agreement1: props.modelValue.agreement1,
  agreement2: props.modelValue.agreement2,
  agreement3: props.modelValue.agreement3,
  agreement4: props.modelValue.agreement4,
  agreement5: props.modelValue.agreement5,
  agreement6: props.modelValue.agreement6,
});
const allChecked = computed(() => {
  return Object.values(innerValue.value).every(v => v === true);
});
const isCheckedAll = (value) => {
  for (let agreement in innerValue.value) {
    innerValue.value[agreement] = value
  }
}

const isAllValidMessage = computed(() => {
  let all = false;
  for (let agreement in props.errors) {
    all = all || props.errors[agreement]
  }
  return all ? 'Wszystkie zgody wymagane' : false;
})

watch(() => props.modelValue, (newValue) => {
  Object.assign(innerValue.value, newValue);
}, { immediate: true });

// Emituj zmiany do rodzica
watch(innerValue, (newVal) => {
  emit('update:modelValue', newVal);
}, { deep: true });
watch(() => props.agreementAdded, (newVal) => {
  if (newVal === true) {
    isCheckedAll(true);
  }
});

const expandAgreements = ref(false);

</script>

<template>
  <div class="grid grid-cols-2 mt-0 gap-2 pt-4">
    <div class="col-span-2 font-light text-xl ">
      Oświadczenia i pełnomocnictwa
    </div>
    <div class="col-span-2 font-light text-xs ">
      Poniżej znajdziesz oświadczenia i pełnomocnictwa niezbędne do realizacji zamówienia. Zapoznaj się z ich treścią, zanim je zaakceptujesz.
    </div>
    <div class="flex items-center col-span-2">
      <div>
        <template>
            <InputCheckBox class="!text-black inline"
                flex
                :looksLikeDisabled="agreementAdded"
                :checked="allChecked"
                @update:checked="isCheckedAll"
                :error="isAllValidMessage"
                :info="isAllValidMessage"
                >
                <span class="!text-black font-light inline">Akceptuję i zaznaczam wszystkie</span>
            </InputCheckBox>
            <ButtonToggle @toggle="(e) => expandAgreements = e">
            <template v-slot:default>
                <span class="text-xs font-extralight flex ml-4"><span>rozwiń wszystkie</span> <IconChevronDown class="!w-3 !h-3 fill-black" /></span>
            </template>
            <template v-slot:toggled>
                <span class="text-xs font-extralight flex ml-4"><span>zwiń</span> <IconChevronDown class="!w-3 !h-3 rotate-180 fill-black" /></span>
            </template>
            </ButtonToggle>
        </template>
        <Accordion
        :expanded="expandAgreements"
            class="pl-4"
        >
          <div class="col-span-2 text-xs pb-4 !text-black font-extralight">
            Udzielam pełnomocnictwa i wyrażam zgodę na przekazanie przez VEHIS do BIK:
          </div>
          <div class="flex flex-col gap-3">
            <InputCheckBox class="!text-black text-xs"
             :looksLikeDisabled="agreementAdded"
            v-model:checked="innerValue.agreement1"
            :error="props.errors.agreement1"
            :info="props.errors.agreement1"
            >
                <span class="!text-black">danych osobowych (zapytanie) w celu pozyskania informacji, w tym stanowiących tajemnicę bankową dotyczących w/w osób jako przedsiębiorców, a także dotyczących w/w osób jako osoby fizyczne – konsumentów, przetwarzanych w BIK dla oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego oraz przetwarzanie w tym celu przez BIK ich danych osobowych przekazanych przez VEHIS w zapytaniu, przez okres nie dłuższy niż 2 lata, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przez w/w osoby zgody.</span>
                <span class="text-rose-600">*</span>
            </InputCheckBox>
            <InputCheckBox class="!text-black"
             :looksLikeDisabled="agreementAdded"
            v-model:checked="innerValue.agreement2"
            :error="props.errors.agreement2"
            :info="props.errors.agreement2"
            >
                <span class="!text-black">informacji dotyczących zobowiązań w/w osób wynikających z zawartej z VEHIS umowy oraz przetwarzanie tych informacji przez BIK do dnia odwołania zgody, nie dłużej jednak niż 5 lat po wygaśnięciu zobowiązania w celu oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów,
                instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przeze w/w osoby zgody. Niniejsza zgoda obejmuje również udostępnianie VEHIS przez BIK, informacji, w tym stanowiących tajemnice bankową dotyczących prowadzonej przeze nie działalności gospodarczej przetwarzanych w BIK dla oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego w trakcie obowiązywania zawartej z VEHIS umowy.
                </span>
                <span class="text-rose-600">*</span>
            </InputCheckBox>
            <InputCheckBox class="!text-black"
             :looksLikeDisabled="agreementAdded"
            v-model:checked="innerValue.agreement3"
            :error="props.errors.agreement3"
            :info="props.errors.agreement3"
            >
                <span class="!text-black">danych osobowych w/w osób, oraz przetwarzanie tych danych przez BIK do dnia odwołania zgody, w celu oceny zdolności leasingowej/pożyczkowej i analizy ryzyka leasingowego/pożyczkowego, w tym ich udostępnianie bankom, instytucjom ustawowo upoważnionym do udzielania kredytów, instytucjom kredytowym oraz innym podmiotom na podstawie udzielonej im przeze w/w osoby zgody.</span>
                <span class="text-rose-600">*</span>
            </InputCheckBox>
        </div>

          <div class="col-span-2 font-light text-xs py-4">
            Ponadto:
          </div>
          <div class="flex flex-col gap-2 font-extralight ml-1.5 overflow-ellipsis overflow-x-hidden text-xs mt-0.5 text-gray-400 !text-black">
            <InputCheckBox class="!text-black"
             :looksLikeDisabled="agreementAdded"
            v-model:checked="innerValue.agreement4"
            :error="props.errors.agreement4"
            :info="props.errors.agreement4"
            >
                <span class="font-bold	!text-black">Oświadczam,</span> <span class="!text-black">że w/w osoby upoważniły VEHIS do wystąpienia do biur informacji gospodarczej (w tym w szczególności Krajowego Rejestru Długów), w tym za pośrednictwem BIK o ujawnienie informacji gospodarczych dotyczących ich zobowiązań, w tym dotyczących ich jako konsumentów.</span>
                <span class="text-rose-600">*</span>
            </InputCheckBox>
            <InputCheckBox class="!text-black"
             :looksLikeDisabled="agreementAdded"
            v-model:checked="innerValue.agreement5"
            :error="props.errors.agreement5"
            :info="props.errors.agreement5"
            >
                <span class="font-bold	!text-black">Oświadczam,</span> <span class="!text-black">że w/w osoby zostały poinformowane o prawie dostępu do swoich danych osobowych, ich poprawiania oraz dobrowolności
                udzielenie zgody na przetwarzanie danych jak również o możliwości odwołania w każdym czasie zgody, złożonej w ich imieniu przez VEHIS.</span>
                <span class="text-rose-600">*</span>
            </InputCheckBox>
            <InputCheckBox class="!text-black"
             :looksLikeDisabled="agreementAdded"
            v-model:checked="innerValue.agreement6"
            :error="props.errors.agreement6"
            :info="props.errors.agreement6"
            >
                <span class="font-bold	!text-black">Oświadczam,</span> <span class="!text-black">że w/w osoby zostały poinformowane o prawie odwołania powyższych zgód w każdym czasie oraz przyjęły do wiadomości, że odwołanie zgody nie wpływa na zgodność z prawem przetwarzania danych przed wycofaniem zgody.</span>
                <span class="text-rose-600">*</span>
            </InputCheckBox>
          </div>
        </Accordion>
        <div class="col-span-2 font-light text-xs mt-4">
      <span class="text-rose-600">*</span> Wymagane
    </div>
      </div>
    </div>
  </div>
</template>
